<template>
  <div>
    <v-card-text>
      <v-form ref="formOrg" v-model="validOrg" lazy-validation>
        <v-radio-group
          :rules="[v => !!v || 'Выберите роль']"
          ref="role"
          class="mt-0"
          v-model="role"
        >
          <v-radio label="Заказчик работ" :value="2"></v-radio>
          <v-radio label="Подрядчик" :value="3"></v-radio>
          <v-radio label="Страховая компания" :value="4"></v-radio>
        </v-radio-group>
        <v-card v-if="role === 3" outlined class="pa-2 mb-5" style="border-color: crimson">
          <v-btn
            class="mb-2"
            color="primary"
            :href="uploadUrl + 'Анкета.xlsx'"
            rounded
            download
            block
          >
            Правила аккредитации
          </v-btn>
          <v-flex class="mb-5">
            В данном документе содержится список документов и информация, которую необходимо
            заполнить и приложить, чтобы получить доступ к площадке.
          </v-flex>
          <v-file-input
            prepend-icon
            type="file"
            ref="doc"
            v-model="attach"
            label="Загрузить документы архивом"
            :rules="uploadRules"
            accept=".rar, .zip, 7z"
            outlined
            dense
          ></v-file-input>
        </v-card>
        <v-card v-if="role === 4" outlined class="pa-2 mb-5" style="border-color: crimson">
          <v-btn
            class="mb-2"
            color="primary"
            :href="uploadUrl + 'insurance.zip'"
            rounded
            download
            block
          >
            Правила аккредитации
          </v-btn>
          <v-flex class="mb-5">
            В данном документе содержится список документов и информация, которую необходимо
            заполнить и приложить, чтобы получить доступ к площадке.
          </v-flex>
          <v-file-input
            prepend-icon
            type="file"
            ref="doc"
            v-model="attach"
            label="Загрузить документы архивом"
            :rules="uploadRules"
            accept=".rar, .zip, 7z"
            outlined
            dense
          ></v-file-input>
        </v-card>
        <v-text-field
          class="input_custom"
          name="lastname"
          ref="lastname"
          label="Фамилия"
          type="text"
          v-model="lastname"
          :rules="[v => !!v || 'Необходимо ввести фамилию']"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="firstname"
          ref="firstname"
          label="Имя"
          type="text"
          v-model="firstname"
          :rules="[v => !!v || 'Необходимо ввести имя']"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="secondname"
          label="Отчество"
          ref="secondname"
          type="text"
          v-model="secondname"
          :rules="[v => !!v || 'Необходимо ввести отчество']"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="orgname"
          label="Наименование организации"
          type="text"
          v-model="orgname"
          ref="orgname"
          :rules="[v => !!v || 'Необходимо ввести наименование']"
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="inn"
          label="ИНН организации"
          type="number"
          ref="inn"
          v-model.lazy="inn"
          :rules="INNRules"
          :error-messages="innMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="kpp"
          label="КПП организации"
          type="number"
          ref="kpp"
          v-model="kpp"
          :rules="KPPRules"
          :error-messages="kppMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="ogrn"
          label="ОГРН/ОГРНИП"
          type="number"
          ref="ogrn"
          v-model="ogrn"
          :rules="OGRNRules"
          :error-messages="ogrnMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="account"
          label="Расчетный счет организации"
          type="number"
          ref="account"
          v-model="account"
          :rules="AccountRules"
          :error-messages="accountMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="corr"
          label="Корреспондентский счет организации"
          type="number"
          ref="corr"
          v-model="corr"
          :rules="CorrRules"
          :error-messages="corrMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="bankname"
          label="Наименование банка"
          ref="bankname"
          v-model="bankname"
          :rules="BanknameRules"
          :error-messages="banknameMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="bic"
          label="БИК"
          type="number"
          ref="bic"
          v-model="bic"
          :rules="BicRules"
          :error-messages="bicMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="manager"
          label="ФИО руководителя"
          ref="manager"
          v-model="manager"
          :rules="ManagerRules"
          :error-messages="managerMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 4"
          class="input_custom"
          name="web"
          label="Web-сайт"
          ref="web"
          @change="domainEqual()"
          v-model="web"
          :rules="WebRules"
          :error-messages="webMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 4"
          class="input_custom"
          name="phone"
          label="Телефон (официальный номер страховой компании)"
          ref="phone"
          v-model="phone"
          :rules="PhoneRules"
          :error-messages="phoneMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          v-if="role === 3 || role === 4"
          class="input_custom"
          name="address"
          label="Адрес местонахождения юр. лица"
          ref="address"
          v-model="address"
          :rules="AddressRules"
          :error-messages="addressMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-list v-if="role === 3" class="mb-5">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>Выбрать город и типы работ</v-list-item-title>
            </template>
            <v-treeview
              v-model="permSelect"
              selection-type="leaf"
              selectable
              return-object
              selected-color="primary"
              :items="permissions"
            ></v-treeview>
          </v-list-group>
        </v-list>
        <v-text-field
          class="input_custom"
          name="emailNewOrg"
          ref="emailNewOrg"
          label="E-mail"
          type="text"
          :error-messages="emailNewOrgMessage"
          v-model.lazy="emailNewOrg"
          :rules="emailRules"
          @change="advanceCheckEmailOrg(), domainEqual()"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="phoneUser"
          label="Телефон пользователя"
          ref="phoneUser"
          v-model="phoneUser"
          :rules="PhoneUserRules"
          :error-messages="phoneUserMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="loginNewOrg"
          label="Логин"
          type="text"
          ref="loginNewOrg"
          :error-messages="loginNewOrgMessage"
          v-model.lazy="loginNewOrg"
          :rules="nameRules"
          @change="advanceCheckLoginOrg()"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          id="password"
          ref="password"
          name="password"
          label="Пароль"
          type="password"
          v-model="password"
          :rules="passwordRules"
          outlined
          required
          dense
          clearable
        ></v-text-field>
        <v-text-field
          id="passwordConfirm"
          ref="passwordConfirm"
          label="Подтвержение пароля"
          type="password"
          v-model="passwordConfirm"
          :rules="passwordConfirmRules"
          outlined
          required
          dense
          clearable
        ></v-text-field>
        <v-layout column class="justify-start">
          <v-flex>
            <span>Для регистрации необходимо подтвердить ваше </span>
            <a
              color="primary"
              :href="uploadUrl + 'Согласие_на_получение_рассылки.pdf'"
              target="blank"
            >
              <span>согласие на получение рассылки</span>
            </a>
            <span> и </span>
            <a color="primary" :href="uploadUrl + 'Согласие_на_обработку_ПД.pdf'" target="blank">
              <span>согласие на обработку персональных данных.</span>
            </a>
          </v-flex>
          <v-flex>
            <v-checkbox v-model="approveMail" label="Согласен на получение рассылки"></v-checkbox>
            <v-checkbox v-model="approveData" label="Согласен на обработку ПД"></v-checkbox>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-4">
      <v-btn color="primary" @click="validateOrg" :disabled="regOrgBtnStatus" block>
        Зарегистрироваться
      </v-btn>
    </v-card-actions>
    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="sitekey"
      @verify="regOrg"
      @expired="onCaptchaExpired"
    />
    <v-dialog v-model="regDialog" width="300">
      <v-flex>
        <v-card :color="colorRegDialog">
          <v-card-text class="white--text">
            {{ textRegDialog }}
            <v-progress-linear
              indeterminate
              v-if="regLinear"
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-dialog>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// eslint-disable-next-line no-useless-escape
const webRegex = /^((?:http(?:s)?\:\/\/)?[a-zA-Z0-9_-]+(?:.[a-zA-Z0-9_-]+)*.[a-zA-Z]{2,4}(?:\/[a-zA-Z0-9_]+)*(?:\/[a-zA-Z0-9_]+.[a-zA-Z]{2,4}(?:\?[a-zA-Z0-9_]+\=[a-zA-Z0-9_]+)?)?(?:\&[a-zA-Z0-9_]+\=[a-zA-Z0-9_]+)*)$/;
export default {
  components: { VueRecaptcha },
  data() {
    return {
      attach: '',
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      permSelect: [],
      citiesList: [],
      worktypes: [],
      role: '',
      orgId: '',
      orgname: '',
      tab: null,
      sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      regDialog: false,
      textRegDialog: '',
      colorRegDialog: '',
      regLinear: false,
      innMessage: '',
      ogrnMessage: '',
      accountMessage: '',
      corrMessage: '',
      banknameMessage: '',
      bicMessage: '',
      managerMessage: '',
      webMessage: '',
      phoneMessage: '',
      phoneUserMessage: '',
      addressMessage: '',
      kppMessage: '',
      lastname: '',
      firstname: '',
      secondname: '',
      email: '',
      emailNewOrg: '',
      login: '',
      loginNewOrg: '',
      loginMessage: '',
      loginNewOrgMessage: '',
      emailMessage: '',
      emailNewOrgMessage: '',
      inn: '',
      kpp: '',
      ogrn: '',
      account: '',
      corr: '',
      bankname: '',
      bic: '',
      manager: '',
      web: '',
      phone: '',
      phoneUser: '',
      address: '',
      password: '',
      passwordConfirm: '',
      minPassLen: 6,
      innMinLength: this.$store.getters.innMinLength,
      innMaxLength: this.$store.getters.innMaxLength,
      kppLength: this.$store.getters.kppLength,
      ogrnMinLength: this.$store.getters.ogrnMinLength,
      ogrnMaxLength: this.$store.getters.ogrnMaxLength,
      accountLength: this.$store.getters.accountLength,
      corrLength: this.$store.getters.corrLength,
      bicLength: this.$store.getters.bicLength,
      validOrg: false,
      orgRules: [v => !!v || 'Необходимо выбрать организацию'],
      nameRules: [v => !!v || 'Необходимо ввести логин'],
      typeRules: [v => v.length != 0 || 'Необходимо выбрать тип'],
      emailRules: [
        v => !!v || 'Введите E-mail',
        v => emailRegex.test(v) || 'E-mail указан неверно'
      ],
      INNRules: [
        v => !!v || 'Необходимо ввести ИНН',
        v =>
          (v.length >= this.innMinLength && v.length <= this.innMaxLength) ||
          `От ${this.innMinLength} до ${this.innMaxLength} цифр`
      ],
      KPPRules: [
        v => !!v || 'Необходимо ввести КПП',
        v => v.length == this.kppLength || `Длина должна быть ${this.kppLength} цифр`
      ],
      OGRNRules: [
        v => !!v || 'Необходимо ввести ОГРН/ОГРНИП',
        v =>
          (v.length >= this.ogrnMinLength && v.length <= this.ogrnMaxLength) ||
          `От ${this.ogrnMinLength} до ${this.ogrnMaxLength} цифр`
      ],
      AccountRules: [
        v => !!v || 'Необходимо ввести расчетный счет организации',
        v => v.length == this.accountLength || `Длина должна быть ${this.accountLength} цифр`
      ],
      CorrRules: [
        v => !!v || 'Необходимо ввести корреспондентский счет организации',
        v => v.length == this.corrLength || `Длина должна быть ${this.corrLength} цифр`
      ],
      BanknameRules: [v => !!v || 'Необходимо ввести наименование банка'],
      BicRules: [
        v => !!v || 'Необходимо ввести БИК',
        v => v.length == this.bicLength || `Длина должна быть ${this.bicLength} цифр`
      ],
      ManagerRules: [
        v => !!v || 'Необходимо ввести ФИО руководителя (индивидуального предпринимателя)'
      ],
      WebRules: [
        v => !!v || 'Введите Web-сайт (Google.com)',
        v => webRegex.test(v) || 'Web-сайт указан неверно'
      ],
      PhoneRules: [v => !!v || 'Введите телефон организации'],
      PhoneUserRules: [
        v => !!v || 'Введите телефон пользователя',
        v => v.length <= 50 || 'не более 50 символов'
      ],
      AddressRules: [v => !!v || 'Необходимо ввести адрес местонахождения юр. лица'],
      passwordRules: [
        v => !!v || 'Необходимо ввести пароль',
        v =>
          (v && v.length >= this.minPassLen) ||
          `Пароль должен быть не менее ${this.minPassLen} символов`
      ],
      passwordConfirmRules: [
        v => !!v || 'Введите пароль',
        v => v === this.password || 'Пароли не соответствует'
      ],
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.size < 10000000) || 'Не более 10 MB!'
      ],
      noDataText: 'Нет данных',
      approveData: false,
      approveMail: false
    };
  },
  methods: {
    domainEqual() {
      if (this.role == 4 && this.emailNewOrg && this.web) {
        const webDomain = this.web
          .split('.')
          .slice(-2)
          .reverse()[1];
        const emailDomain = this.emailNewOrg.split('@')[1].split('.')[0];
        this.webMessage =
          webDomain == emailDomain ? '' : 'Доменные имена E-mail и web не совпадают';
        this.emailNewOrgMessage =
          webDomain == emailDomain ? '' : 'Доменные имена E-mail и web не совпадают';
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    alertRegStatus(colorRegDialog, textRegDialog, regDialog, regLinear) {
      (this.colorRegDialog = colorRegDialog),
        (this.textRegDialog = textRegDialog),
        (this.regDialog = regDialog),
        (this.regLinear = regLinear);
    },
    advanceCheckEmailOrg() {
      if (this.$refs.emailNewOrg.validate()) {
        return new Promise(resolve => {
          const action = 'advanceCheckEmail';
          const dataParams = {
            email: this.emailNewOrg
          };
          this.$store
            .dispatch('login', { action, dataParams })
            .then(resp => {
              if (resp.result == 'success') {
                this.emailNewOrgMessage = '';
              } else {
                this.emailNewOrgMessage = 'Пользователь с таким E-mail уже существует';
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    advanceCheckLoginOrg() {
      if (this.$refs.loginNewOrg.validate()) {
        return new Promise(resolve => {
          const action = 'advanceCheckLogin';
          const dataParams = {
            login: this.loginNewOrg
          };
          this.$store
            .dispatch('login', { action, dataParams })
            .then(resp => {
              if (resp.result == 'success') {
                this.loginNewOrgMessage = '';
              } else {
                this.loginNewOrgMessage = 'Пользователь с таким логином уже существует';
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    validateOrg() {
      if (this.$refs.formOrg.validate()) this.$refs.recaptcha.execute();
    },
    regOrg(recaptchaToken) {
      this.alertRegStatus('blue', 'Регистрация...', true, true);
      return new Promise(resolve => {
        const action = 'regOrg';
        const dataParams = {
          lastname: this.lastname,
          firstname: this.firstname,
          secondname: this.secondname,
          orgname: this.orgname,
          inn: this.inn,
          kpp: this.kpp,
          ogrn: this.ogrn,
          account: this.account,
          corr: this.corr,
          bankname: this.bankname,
          bic: this.bic,
          manager: this.manager,
          web: this.web,
          phone: this.phone,
          phoneUser: this.phoneUser,
          address: this.address,
          permissions: this.permSelect,
          role: this.role,
          email: this.emailNewOrg,
          login: this.loginNewOrg,
          password: this.password,
          attach: this.attach,
          attachName: this.attach.name,
          recaptchaToken: recaptchaToken,
          approveData: this.approveData,
          approveMail: this.approveMail
        };
        this.$store
          .dispatch('login', { action, dataParams })
          .then(resp => {
            switch (resp.result) {
              case 'success':
                this.alertRegStatus(
                  'success',
                  'На вашу почту отправлено сообщение. Для подтверждения регистрации перейдите по ссылке в письме',
                  true,
                  false
                );
                setTimeout(() => {
                  this.$router.push('/login');
                }, 6000);
                break;
              case 'error_login':
                this.alertRegStatus(
                  'error',
                  'Пользователь с таким логином уже существует',
                  true,
                  false
                );
                setTimeout(() => {
                  this.regDialog = false;
                }, 3000);
                break;
              case 'error_email':
                this.alertRegStatus(
                  'error',
                  'Пользователь с таким email уже существует',
                  true,
                  false
                );
                setTimeout(() => {
                  this.regDialog = false;
                }, 3000);
                break;
              default:
                this.alertRegStatus('error', 'Ошибка регистрации. Попробуйте позже', true, false);
                setTimeout(() => {
                  this.regDialog = false;
                }, 3000);
                break;
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getWorkTypes() {
      return new Promise(resolve => {
        const action = 'getWorkTypes';
        const dataParams = {};
        this.$store
          .dispatch('login', { action, dataParams })
          .then(resp => {
            this.worktypes = resp.worktypes;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getCitiesList() {
      return new Promise(resolve => {
        const action = 'getCitiesList';
        const dataParams = {};
        this.$store
          .dispatch('login', { action, dataParams })
          .then(resp => {
            this.citiesList = resp.citiesList;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    permissions() {
      const array = [];
      let id = 0;
      this.citiesList.forEach(city => {
        const subArray = [];
        this.worktypes.forEach(worktypes => {
          subArray.push({
            id: id++,
            typeId: worktypes.id,
            cityId: city.id,
            name: worktypes.type
          });
        });
        array.push({ id: id++, name: city.name, children: subArray });
      });
      return array;
    },
    regOrgBtnStatus() {
      if (
        //Условия для роли 'Заказчик'
        this.role == '2' &&
        this.lastname != '' &&
        this.firstname != '' &&
        this.secondname != '' &&
        this.orgname !== '' &&
        this.inn.length >= this.innMinLength &&
        this.inn.length <= this.innMaxLength &&
        this.emailNewOrg != '' &&
        this.loginNewOrg != '' &&
        this.password != '' &&
        this.passwordConfirm != '' &&
        this.validOrg &&
        this.approveData &&
        this.approveMail &&
        this.phoneUser
      ) {
        return false;
      } else if (
        //Условия для роли 'Подрядчик'
        this.role == '3' &&
        this.lastname != '' &&
        this.firstname != '' &&
        this.secondname != '' &&
        this.orgname !== '' &&
        this.inn.length >= this.innMinLength &&
        this.inn.length <= this.innMaxLength &&
        this.kpp.length == this.kppLength &&
        this.ogrn.length >= this.ogrnMinLength &&
        this.ogrn.length <= this.ogrnMaxLength &&
        this.account.length == this.accountLength &&
        this.corr.length == this.corrLength &&
        this.bankname !== '' &&
        this.bic.length == this.bicLength &&
        this.manager !== '' &&
        this.address !== '' &&
        this.attach &&
        this.emailNewOrg != '' &&
        this.loginNewOrg != '' &&
        this.password != '' &&
        this.passwordConfirm != '' &&
        this.validOrg &&
        this.approveData &&
        this.approveMail &&
        this.phoneUser
      ) {
        return false;
      } else if (
        //Условия для роли 'Страховая компания'
        this.role == '4' &&
        this.lastname != '' &&
        this.firstname != '' &&
        this.secondname != '' &&
        this.orgname !== '' &&
        this.inn.length >= this.innMinLength &&
        this.inn.length <= this.innMaxLength &&
        this.account.length == this.accountLength &&
        this.corr.length == this.corrLength &&
        this.bankname !== '' &&
        this.bic.length == this.bicLength &&
        this.manager !== '' &&
        this.web !== '' &&
        this.phone !== '' &&
        this.address !== '' &&
        this.attach &&
        this.emailNewOrg != '' &&
        this.loginNewOrg != '' &&
        this.password != '' &&
        this.passwordConfirm != '' &&
        this.validOrg &&
        this.phoneUser
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.getWorkTypes();
    this.getCitiesList();
    this.advanceCheckEmailOrg();
    this.advanceCheckLoginOrg();
  }
};
</script>
<style scoped>
.reset_btn_wrap {
  text-align: end;
}
.input_custom > input {
  background-color: rgb(255, 255, 255) !important;
}
</style>
